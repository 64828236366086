export const textVopros = [
    {
        value: '1',
        vopros: 'Сколько стоит диагностика?',
        otvet: 'Первичная диагностика производится БЕСПЛАТНО'
    },
    {
        value: '2',
        vopros: 'Если после диагностики я откажусь от ремонта?',
        otvet: 'Платить ничего не нужно, первичная диагностика бесплатная'
    },
    {
        value: '3',
        vopros: 'Сколько времени занимает диагностика?',
        otvet: 'Срок диагностики зависит от сложности, загруженности мастера и других причин, заранее невозможн предугадать время, но можно заранее обговорить время доступное у заказчика'
    },
    {
        value: '4',
        vopros: 'Сколько стоит ремонт?',
        otvet: 'Стоимость ремонта определяется и согласуется с заказчиком в каждом отдельном случае индивидуально после диагностики. Стоимость наиболее популярных услуг можно увидеть в соответствующем разделе сайта'
    },
    {
        value: '5',
        vopros: 'Есть ли гарантия на выполненные работы?',
        otvet: 'На все выполняемые работы предоставляется гарантия до 120 дней, в некоторых случаях когда повреждения связаны с попаданием жидкости или ремонт не рекомендуется, а заказчик настаивает на ремонте гарантия может не предоставляться'
    },
    {
        value: '6',
        vopros: 'Возможен ли срочный ремонт?',
        otvet: 'Срочный ремонт возможен только по предварительному согасованию с мастером'
    },

]